import React from 'react';
import { createTheme } from '@material-ui/core/styles';
import { CheckBoxOutlined } from '@material-ui/icons';
import { viinimaaNavigationBreakpoint } from '../../constants';

import '../../fonts/PlayfairDisplay/PlayfairDisplay.css';

export const colors = {
  black: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  white50: 'rgba(255, 255, 255, 0.5)',
  whiteTransparent: 'rgba(255, 255, 255, 0.9)',
  gray: 'rgb(204, 204, 204)',
  lighterGray: 'rgb(244, 245, 246)',
  lightGray: 'rgb(230, 230, 230)',
  mediumGray: 'rgb(155, 155, 155)',
  mediumGray2: 'rgb(129, 129, 129)',
  darkGray: 'rgb(51, 51, 51)',
  darkerGray: 'rgb(129, 129, 129)',
  blue: 'rgb(70, 152, 202)',
  lightBlue: 'rgb(164, 200, 225)',
  lightBlueTransparent: 'rgba(164, 200, 225, 0.9)',
  lighterBlue: 'rgb(234, 243, 249)',
  green: 'rgb(139, 181, 80)',
  lightGreen: 'rgb(176, 219, 116)',
  lightGreenTransparent: 'rgba(176, 219, 116, 0.9)',
  lighterGreen: 'rgb(241, 246, 236)',
  yellow: 'rgb(222, 184, 59)',
  lightYellow: 'rgb(233, 225, 134)',
  lighterYellow: 'rgb(255, 246, 233)',
  lightYellowTransparent: 'rgba(233, 225, 134, 0.9)',
  lighterYellow2: '#fff6e9',

  red: 'rgb(161, 21, 60)',
  darkRed: ' rgb(117, 13, 13)',
  lightRed: 'rgb(249, 234, 236)',

  darkOrange: '#ba8142',
  brightBlue: '#43847c',
  brightyellow: '#ffd700',

  brightGreen: '#6c8551',
  brightBrown: '#86482c',

  pink: 'rgb(208, 1, 110)',
  darkBrown: '#6D3332',
  brightOrange: '#FF4F01',
  purple: '#685BC7',
  lightPink: 'rgb(221, 169, 191)',
};

const theme = createTheme({});
const breakPoints = {
  xs: 0,
  sm: 600,
  md: 840,
  lg: 1280,
  xl: 1920,
};
const primaryTheme = createTheme({
  breakpoints: {
    values: breakPoints,
  },
  mixins: {
    toolbar: {
      minHeight: '70px',
    },
  },
  typography: {
    fontFamily: 'proxima-nova, sans-serif',
    fontWeightLight: '300',
    fontWeightRegular: '400',
    fontWeightMedium: '500',
    fontWeightBold: '700',
    h1: {
      fontFamily: '"Playfair Display", serif',
      fontSize: '1.75rem',
      fontWeight: '700',
      lineHeight: '1.19',
      marginBottom: '1rem',
      [theme.breakpoints.up(breakPoints.md)]: {
        fontSize: '2.625rem',
      },
    },
    h2: {
      fontFamily: '"Playfair Display", serif',
      fontSize: '1.375rem',
      fontWeight: '700',
      lineHeight: 'normal',
      marginBottom: '1rem',
      [theme.breakpoints.up(breakPoints.md)]: {
        fontSize: '1.5rem',
      },
    },
    h3: {
      fontFamily: '"Playfair Display", serif',
      fontSize: '1.125rem',
      fontWeight: '700',
      lineHeight: 'normal',
      marginBottom: '1rem',
    },
    h4: {
      fontFamily: '"Playfair Display", serif',
      fontSize: '1rem',
      fontWeight: '700',
      lineHeight: 'normal',
      marginBottom: '1rem',
    },
    h5: {
      fontFamily: '"Playfair Display", serif',
      fontSize: '1rem',
      fontWeight: '700',
      lineHeight: 'normal',
      marginBottom: '1rem',
    },
    h6: {
      fontFamily: '"proxima-nova", sans-serif',
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: 'normal',
      marginBottom: '1rem',
    },
    caption: {
      fontSize: 'inherit',
      fontStyle: 'italic',
      lineHeight: '1.5',
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: '700',
    },
    body1: {
      fontFamily: 'proxima-nova, sans-serif',
      fontSize: '0.938rem',
      [theme.breakpoints.up(breakPoints.md)]: {
        fontSize: '1rem',
      },
    },
    body2: {
      fontFamily: 'proxima-nova, sans-serif',
      fontSize: '0.75rem',
      [theme.breakpoints.up(breakPoints.md)]: {
        fontSize: '0.875rem',
      },
    },
    navigationLink: {
      fontSize: '1rem',
      fontFamily: 'proxima-nova, sans-serif',
      fontWeight: 'bold',
      color: colors.black,
    },
    descriptionRow: {
      fontSize: 'initial',
    },
  },
  palette: {
    common: {
      black: colors.black,
      white: colors.white,
      gray: colors.gray,
      lightGray: colors.lightGray,
      lighterGray: colors.lighterGray,
      mediumGray: colors.mediumGray,
      darkGray: colors.darkGray,
      blue: colors.blue,
      lightBlue: colors.lightBlue,
      red: colors.red,
      darkRed: colors.darkRed,
      green: colors.green,
      lightGreen: colors.lightGreen,
    },
    primary: {
      main: colors.black,
      contrastText: colors.white50,
    },
    secondary: {
      main: colors.darkGray,
      light: colors.lightYellow,
    },
    text: {
      primary: colors.darkGray,
      secondary: colors.white,
      light: colors.white,
    },
    background: {
      default: colors.white,
      breadcrumbs: colors.lightGray,
      linkBlock: colors.lightGray,
      defaultColumn: 'transparent',
      defaultRow: 'transparent',
    },
    error: {
      main: colors.red,
      light: colors.lightRed,
    },
    success: {
      main: colors.green,
      light: colors.lighterGreen,
    },
    warning: {
      main: colors.brightOrange,
      light: colors.lighterYellow,
    },
    info: {
      main: colors.blue,
      light: colors.lighterBlue,
    },
    headingFontFamily: { fontFamily: '"Playfair Display", serif' },
    divider: colors.lightGray,
    category: {
      outlined: {
        background: colors.lightGray,
        text: colors.red,
      },
      text: colors.mediumGray,
      hoverText: colors.red,
    },
    footer: {
      border: {
        color: colors.red,
        width: '3px',
      },
      divider: colors.green,
      text: colors.black,
      background: colors.white,
      logoBarBackground: colors.lightGray,
      padding: {
        bottom: '50px',
      },
      columns: {
        list: null,
      },
      texts: null,
    },
    header: {
      text: colors.black,
      background: colors.white,
      border: {
        bottomWidth: '3px',
        style: 'solid',
        color: colors.red,
      },
      minHeight: '64px',
      paddingYDesktop: '24px',
      paddingTop: '0',
      backgroundDesktop: colors.green,
      borderBottomColor: colors.red,
      navigation: {
        activeLink: colors.red,
        activeLinkBorder: `4px solid ${colors.green}`,
        hoverText: colors.red,
        subMenuBackground: colors.lighterGray,
        subMenuBulletPoints: colors.red,
      },
      icons: {
        color: null,
        backgroundColor: 'transparent',
      },
    },
    search: {
      quickSearch: {
        inputContainer: {
          backgroundColor: colors.white,
          [theme.breakpoints.up('sm')]: {
            backgroundColor: colors.green,
          },
        },
        hideButton: {
          color: colors.white,
        },
      },
    },
    mobileNavigation: {
      text: colors.black,
      icon: colors.red,
      xIconBackground: colors.lighterGray,
      logoBarBottomBorder: colors.red,
      navigationElementBorder: colors.lightGray,
      subNavigationTitleBackground: colors.lighterGray,
      subNavigationTitleColor: colors.red,
    },
    productPage: {
      informationBackground: colors.lighterGray,
      tasteProfiles: {
        orange: colors.darkOrange,
        yellow: colors.brightyellow,
        green: colors.brightGreen,
        brown: colors.brightBrown,
        blue: colors.brightBlue,

        pink: colors.pink,
        darkBrown: colors.darkBrown,
        brightOrange: colors.brightOrange,
        purple: colors.purple,
        lightPink: colors.lightPink,
      },
      mainInfo: {
        accordion: {
          color: colors.red,
          information: {
            detailesKey: colors.darkerGray,
          },
        },
      },
      review: {
        articlePadding: theme.spacing(0, 2, 0, 2),
        background: colors.lighterGray,
        divider: colors.darkerGray,
      },
      labelBackground: colors.red,
    },
    quote: {
      text: colors.green,
      fontSize: '19px',
    },
    button: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
      [theme.breakpoints.up(breakPoints.md)]: {
        padding: '15px 10px 15px 10px',
      },
    },
    memoryListButton: {
      badge: { backgroundColor: colors.green, color: colors.black },
    },
    link: {
      link: colors.red,
      active: colors.red,
      hover: colors.red,
    },
    pageTemplates: {
      landingPage: {
        paddingTop: theme.spacing(20),

        [theme.breakpoints.up(viinimaaNavigationBreakpoint.Breakpoint)]: {
          paddingTop: theme.spacing(10),
        },
      },
      otherPageTemplates: {
        paddingTop: theme.spacing(20),
        [theme.breakpoints.up(viinimaaNavigationBreakpoint.Breakpoint)]: {
          paddingTop: theme.spacing(15),
        },
      },
      recipePage: {
        paddingTop: theme.spacing(20),
        [theme.breakpoints.up(viinimaaNavigationBreakpoint.Breakpoint)]: {
          paddingTop: theme.spacing(15),
        },
      },
    },
    custobarSubscriptionForm: {
      background: colors.green,
      hover: {
        background: colors.lightGreen,
      },
      disabled: {
        background: colors.green,
      },
    },
    siteNotifications: {
      backgroundColor: colors.lighterYellow2,
      color: '#ffa31c',
      padding: theme.spacing(2.5),
      margin: theme.spacing(-3, 0, 4, 0),
      [theme.breakpoints.up(viinimaaNavigationBreakpoint.Breakpoint)]: {
        margin: theme.spacing(-1, 0, 4, 0),
      },
    },
    monopolyPrice: {
      fontFamily: 'proxima-nova, sans-serif',
      reducedPriceColor: colors.red,
    },
  },

  accordion: {
    backgroundColor: colors.white,
    color: colors.darkRed,
    border: '',
    expandIcon: {
      backgroundColor: colors.lighterGray,
      hover: {
        backgroundColor: colors.gray,
      },
    },
  },
  languageSelector: {
    color: colors.black,
    border: {
      color: colors.black,
      width: '2px',
    },
    margin: '0',
  },
  linkBlockModule: {
    imageWrapper: {
      maxWidth: '40%',
    },
    textWrapper: {},
  },
  table: {
    border: `solid 1px ${colors.black}`,
    borderRadius: 0,
    head: {
      backgroundColor: colors.black,
      color: colors.white,
    },
    row: {
      even: {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  listElementCard: {
    content: {},
  },
  slider: {
    arrowBotton: {},
  },
  hr: {},

  imageSpotLink: {
    innerDot: {},
  },
  event: {
    root: {},
    day: {},
    month: {},
    name: {},
    shortDescription: {},
    icon: {},
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
      colorPrimary: {
        color: colors.black,
        backgroundColor: colors.white,
      },
    },
    MuiToolbar: {
      regular: {},
      dense: {
        color: colors.white,
      },
    },
    MuiDrawer: {
      root: {
        width: '350px',
        flexShrink: 0,
      },
      paper: {
        width: '350px',
        color: colors.black,
        backgroundColor: colors.white,
        maxWidth: '100%',
      },
    },
    MuiLink: {
      root: {
        color: colors.red,
      },
    },
    MuiDialog: {
      paper: {
        margin: '5px',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'inherit',
        borderRadius: '0px',
        padding: '18px 20px',
        fontSize: '1rem',
        minWidth: '0',
      },
      sizeSmall: {
        padding: '9px 18px',
        borderRadius: '0px',
        fontSize: '0.8125rem',
      },
      containedPrimary: {
        boxShadow: 'none',
        border: '2px solid transparent',
        backgroundColor: colors.red,
        color: colors.white,
        '&:hover': {
          backgroundColor: colors.white,
          border: `2px solid ${colors.red}`,
          boxShadow: 'none',
          color: colors.red,
          '@media (hover: none)': {
            backgroundColor: colors.white,
          },
        },
        '&$disabled': {
          color: colors.black,
          opacity: 0.7,
        },
      },
      outlinedPrimary: {
        border: `2px solid ${colors.red}`,
        color: colors.red,
        '&:hover': {
          backgroundColor: colors.red,
          border: `2px solid transparent`,
          color: colors.white,
          '@media (hover: none)': {
            backgroundColor: colors.red,
          },
        },
      },
    },
    MuiBreadcrumbs: {
      root: {
        color: colors.darkGray,
      },
      separator: {
        fontSize: '1.5rem',
        marginLeft: '16px',
        marginRight: '16px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '8px 8px',
        [theme.breakpoints.up('sm')]: {
          padding: '8px 24px',
        },
      },
    },
    MuiInputBase: {
      root: {
        border: '1px solid',
        borderColor: colors.gray,
        overflow: 'hidden',
        backgroundColor: colors.lightGray,
        '&:hover:not($disabled)': {
          borderColor: colors.gray,
        },
        '& > svg': {
          color: colors.gray,
        },
        '&$focused': {
          boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.24)',
          borderColor: colors.green,
          '&:hover:not($disabled)': {
            borderColor: colors.green,
          },
        },
        '&$error:not($focused)': {
          borderColor: theme.palette.error.main,
          '&:hover:not($disabled)': {
            borderColor: theme.palette.error.main,
          },
        },
        '&$disabled': {
          backgroundColor: colors.lightGray,
        },
      },
      adornedStart: {
        paddingLeft: '0.5rem',
      },
      adornedEnd: {
        paddingRight: '0.5rem',
      },
      input: {
        fontSize: '1rem',
        padding: '0.625rem 1rem',
        '&:not(:first-child)': {
          paddingLeft: '0.5rem',
        },
        '&:not(:last-child)': {
          paddingRight: '0.5rem',
        },
        '&::placeholder': {
          fontStyle: 'italic',
        },
      },
    },
    MuiInputLabel: {
      root: {
        textTransform: 'uppercase',
        marginLeft: '0.75rem',
      },
      shrink: {
        marginTop: '-8px',
        transform: 'translate(0, 1.5px) scale(1)',
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.darkGray,
        '&$error': {
          color: colors.darkRed,
        },
      },
      asterisk: {
        '&$error': {
          color: colors.darkGray,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: colors.darkGray,
        marginLeft: '0.75rem',
        '&$error': {
          color: colors.darkRed,
        },
      },
    },

    MuiCheckbox: {
      root: {
        color: colors.darkGray,
        '&$checked': {
          color: colors.darkGray,
        },
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: theme.spacing(2),
      },
    },
    MuiTypography: {
      root: {
        '& > li::Marker': {
          color: colors.red,
        },
      },
    },
    MuiList: {
      root: {
        listStyle: 'square',
        '& > li::Marker': {
          color: colors.red,
        },
      },
    },
    MuiCollapse: {
      hidden: {
        '@media print': {
          visibility: 'visible',
          height: 'auto',
        },
      },
    },
    MuiPaginationItem: {
      textPrimary: {
        '&.Mui-selected': {
          backgroundColor: colors.red,
          color: colors.white,
          fontWeight: 'bold',
        },
      },
    },
    MuiSlider: {
      thumb: {
        color: colors.red,
        '&:after': {
          display: 'none',
        },
      },
      track: {
        color: colors.red,
      },
    },
    PrivateValueLabel: {
      circle: {
        borderRadius: '50%',
      },
      label: {
        color: colors.white,
        fontWeight: 'bold',
      },
      offset: {
        top: 0,
      },
    },
  },
  props: {
    MuiAppBar: {
      color: 'primary',
    },
    MuiIconButton: {
      color: 'primary',
    },
    MuiButton: {
      color: 'primary',
      variant: 'contained',
    },
    MuiLink: {
      variant: 'body1',
    },
    MuiContainer: {
      disableGutters: false,
    },
    MuiBreadcrumbs: {
      separator: '›',
    },
    MuiInput: {
      disableUnderline: true,
    },
    MuiInputLabel: {
      shrink: true,
    },
    MuiCheckbox: {
      checkedIcon: <CheckBoxOutlined />,
    },
  },
});

export default primaryTheme;
